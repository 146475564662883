









































































.diagrams-container {
  overflow: hidden;
  z-index: 0;

  canvas {
    &:focus {
      outline: 0;
    }
  }

  div {
    display: none;
  }
}
