<template>
  <div class="q-pa-xs" :class="cssClass" :id="comment.id">
    <q-item dense>
      <q-item-section side>
        <AppAvatar
          :label="getName(comment.createdBy)"
          :avatar="getAvatar(comment.createdBy)"
          size="30px"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label>
          {{ user.id === comment.createdBy ? 'me' : creators[comment.createdBy].displayName }}
        </q-item-label>
        <q-item-label caption>
          {{ comment.createdAt|formatDatetime }}
          <list-tooltip v-if="comment.createdAt !== comment.updatedAt">
            Last Edited {{ comment.updatedAt | formatDatetime }}
          </list-tooltip>
        </q-item-label>
      </q-item-section>
      <q-item-section side>
        <Btn
          v-if="!isReply && comment.callout > 0"
          dense
          round
          size="sm"
          :color="calloutColor"
          text-color="white"
          :label="comment.callout"
          class="q-ml-md"
          @click="selectCallout(comment)"
        />
      </q-item-section>
      <q-item-section side>
        <CommentButtons
          :comment="comment"
          class="q-ml-md"
          @open="resetStatus"
          @click="onClick"
        />
      </q-item-section>
    </q-item>

    <p class="text-subtitle1 white-space-pre-wrap q-ml-md q-mt-md q-mb-sm">
      {{ comment.label }}
    </p>

    <div class="flex items-center justify-end">
      <Btn
        v-if="!isReply && comment.status === 'active'"
        flat
        color="accent"
        label="Reply"
        @click="onClick(actions.REPLY)"
      />
    </div>

    <div class="q-ml-lg" v-if="comment.replies && comment.replies.length">
      <ViewComment
        v-for="reply in comment.replies"
        :key="reply.id"
        is-reply
        :comment="reply"
        :creators="creators"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Diagram } from 'gojs';
import { UserDetails } from '@/types/profile';
import { CalloutData } from '@/bridge/types/diagramModel';
import { CommentActions, CommentModel, UpdateCalloutData } from '@/types/comments';
import { calloutColor } from '@/bridge/shapes/callout';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import notify from '@/util/notify';
import ApiService from '@/services/api.service';
import Btn from '@/components/buttons/Btn.vue';
import CommentButtons from '@/views/diagramComments/CommentButtons.vue';
import AppAvatar from '@/components/avatar/Avatar.vue';
import ListTooltip from '@/components/list/ListTooltip.vue';
import { CalloutCategory } from '@/bridge/enums/partCategories';

export default Vue.extend({
  name: 'ViewComment',
  components: {
    ListTooltip,
    AppAvatar,
    CommentButtons,
    Btn,
  },
  props: {
    comment: {
      type: Object as PropType<CommentModel>,
      required: true,
    },
    creators: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cssClass(): string {
      const common = 'bordered rounded-borders';
      if (this.isReply) {
        return `${common} bg-white q-mb-sm`;
      }
      if (this.comment.status === 'approved') {
        return `${common} bg-green-1 q-mb-lg`;
      }
      return `${common} bg-grey-2 q-mb-lg`;
    },
    isReply(): boolean {
      return this.comment && !!this.comment.parentId;
    },
    bgColor(): string {
      if (this.comment.status === 'active') {
        return this.comment.parentId ? 'grey-3' : 'grey-2';
      }
      return 'grey';
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    user(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    resolveUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/comments/resolve?versionIds=${this.versionId}`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/comments/resolve?versionIds=${this.versionId}`;
    },
    removeUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/comments`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/comments`;
    },
  },
  data() {
    return {
      actions: CommentActions,
      editContent: '',
      editing: false,
      replying: false,
      error: '',
      diagram: null as Diagram | null,
      calloutColor: '',
    };
  },
  methods: {
    resetStatus() {
      // If the user clicks on Add Callout then he clicks on another button
      // without adding a callout, we need to reset the status.
      document.body.classList.remove('cursor-add-callout');
      if (this.diagram) {
        (this.diagram as any)._addCallout = null;
      }
    },
    async onClick(action: CommentActions) {
      if (action === CommentActions.ADD_CALLOUT) {
        await this.addCallout();
        return;
      }
      if (action === CommentActions.RESOLVE) {
        await this.resolveComment();
        return;
      }
      if (action === CommentActions.REMOVE_CALLOUT) {
        await this.removeCallout();
        return;
      }

      this.$emit('click', {
        action,
        comment: this.comment,
      });
    },
    getName(id: string) {
      if (this.creators && this.creators[id]) {
        return this.creators[id].displayName;
      }
      return '';
    },
    getAvatar(id: string) {
      if (this.creators && this.creators[id]) {
        return this.creators[id].avatar;
      }
      return '';
    },
    async addCallout() {
      if (this.diagram) {
        document.body.classList.add('cursor-add-callout');
        this.diagram.clearSelection();

        (this.diagram as any)._addCallout = {
          commentId: this.comment.id,
          createdBy: `${this.user.forename} ${this.user.surname}`,
          createdAt: Date.now(),
          status: this.comment.status,
          isGuest: this.$isOrgRole(this.$Roles.GUEST),
          ...this.$route.params,
        };
      }
    },
    async removeCallout() {
      try {
        await ApiService.put(
          this.removeUrl,
          { callout: -1, versionId: this.versionId, id: this.comment.id },
        );
        diagramEvents.$emit(diagramEvents.DELETE_CALLOUT, this.comment.id);
      } catch (e) {
        notify.info('Could not remove callout');
      }

      this.$emit('reload');
      this.setCalloutColor();
    },
    async resolveComment() {
      try {
        await ApiService.put(this.resolveUrl, { ids: [this.comment.id] });
        diagramEvents.$emit(diagramEvents.UPDATE_CALLOUT, {
          commentId: this.comment.id,
          status: 'approved',
          visible: false,
        } as UpdateCalloutData);
        this.$emit('reload');
        notify.success('Comment successfully resolved');
      } catch (e: any) {
        notify.danger('Comment could not be resolved');
      }

      this.setCalloutColor();
    },
    selectCallout(comment: any) {
      if (comment.callout > 0) {
        diagramEvents.$emit(diagramEvents.SELECT_CALLOUT, comment.id);
        this.$emit('selectCallout');
      }
    },
    setCalloutColor() {
      if (this.comment.callout < 1) {
        this.calloutColor = '';
        return;
      }

      if (this.comment.status === 'approved') {
        this.calloutColor = calloutColor.approved.name;
        return;
      }
      if (this.diagram) {
        const callout = this.diagram.findNodesByExample({
          createdBy: `${this.user.forename} ${this.user.surname}`,
          commentId: this.comment.id,
          category: CalloutCategory.DEFAULT,
        } as Partial<CalloutData>).first();

        if (callout) {
          this.calloutColor = calloutColor.myCallout.name;
          return;
        }
      }

      this.calloutColor = calloutColor.active.name;
    },
  },
  created() {
    this.diagram = Diagram.fromDiv(this.$store.getters['diagramModule/diagramDivId']);
    this.setCalloutColor();
  },
});
</script>

<style>
.q-message-container .q-message-avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
</style>
